
      window.onload = function () {
        var host = window.location.hostname;
        var favicon = document.querySelector(
          'link[rel="icon"][href="/favicon.png"]',
        );
        if (favicon) {
          if (host === "app.alpha.towns.com") {
            favicon.href = "/favicon-teal.png";
          } else if (host === "app.gamma.towns.com") {
            favicon.href = "/favicon-blue.png";
          } else if (host === "app.delta.towns.com") {
            favicon.href = "/favicon-brown.png";
          } else if (host === "fast-app.towns.com") {
            favicon.href = "/favicon-black.png";
          } else if (host === "localhost") {
            favicon.href = "/favicon-green.png";
          }
        }
      };
    